.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem;
}

.messageList {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.messageWrapper {
  display: flex;
  margin-bottom: 1rem;
}

.messageWrapper.question {
  justify-content: flex-end;
}

.messageWrapper.answer {
  justify-content: flex-start;
}

.message {
  max-width: 75%;
  padding: 1rem;
  border-radius: 0.5rem;
}

.question .message {
  background-color: #3b82f6;
  color: white;
}

.answer .message {
  background-color: #e5e7eb;
  color: #1f2937;
}

.messageType {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.inputForm {
  display: flex;
}

.input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-right: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.input:focus {
  outline: none;
  /* ring: 2px solid #3b82f6; */
}

.error {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  text-align: center;
}

.loading {
  text-align: center;
  color: #6b7280;
  padding: 1rem;
}

/* ... (previous styles remain the same) ... */

.inputForm {
  display: flex;
}

.select {
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-right: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  background-color: white;
}

.input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-right: none;
  border-left: none;
}

.submitButton {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem;
  border: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #2563eb;
}

.select:focus,
.input:focus,
.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.select:disabled,
.input:disabled,
.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}